import { Tooltip, Typography } from '@mui/material';
import { EMPTY_VALUE_PLACEHOLDER } from 'utils/formatting';

interface TooltipCellProps {
  readonly text?: string;
}

export const TooltipCell = ({ text }: TooltipCellProps) => {
  return (
    <Tooltip title={text}>
      <Typography
        variant="body2"
        sx={{
          color: 'greys.900',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {text || EMPTY_VALUE_PLACEHOLDER}
      </Typography>
    </Tooltip>
  );
};
